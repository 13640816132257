*{
    --antd-wave-shadow-color: 0 !important;
    --scroll-bar: 0 !important
}

body{
    font-family: unset;
    font-variant: unset;
    color: unset;
    font-size: unset;
    font-feature-settings: unset;
    background-color: unset;
}

a, a:hover{
    color: unset;
}