@import 'assets/theme/override-ngzorro.scss';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";


@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,900&family=Open+Sans:wght@400;500;700&display=swap');


::selection{
    @apply text-white bg-secondary
}

h1, h2, h3, h4, h5, h6{
    @apply font-title
}

p, a, ul, li, ul li, form, label, select, select option, nav, nav *{
    @apply font-text
}

label{
    @apply font-text text-slate-600
}

.w-btn{
    @apply font-text text-white bg-main hover:bg-darker-blue rounded-md
}

.w-btn-border {
    @apply font-text text-main ring-1 ring-inset ring-main hover:text-white hover:bg-darker-blue rounded-md
}
